import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'

class DesignPage extends React.Component {
  render() {
    const siteTitle =
      'Automatic Gate Designs - Arlington, TX and Dallas-Fort Worth, TX'
    const siteDescription =
      'We Offer Custom Gate Design and Installation in Arlington, TX. Call Us at 817-466-2794 For Dallas-Fort Worth, TX Gate Services'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Gate Designs</h1>
            <hr />
          </div>
        </div>
        <div className="container d-padding-t d-padding-b">
          <div className="row gates-row4 text-center" id="gate-designs-row">
            <p className="mx-auto mb-5">
              No matter which style of gate you’re looking for, J & J Gates
              Service and Design has the perfect option for you! Do you have
              limited space? Want something a little more decorative and unique?
              Our expert installers can help you find the ideal type of gate
              design for your property. We want to ensure that your home or
              business is both secure and aesthetically pleasing. With several
              options to choose from, you can find the perfect style, color,
              material, and gate designs that you enjoy.{' '}
              <strong>
                <em>
                  Whether you need residential or commercial gates, call J & J
                  Gates Service and Design at{' '}
                  <a href="tel:+1-817-466-2794">817-466-2794</a> for questions
                  and assistance!
                </em>
              </strong>
            </p>
            <div className="col-md-4 ">
              <div className="gate-svc-cont">
                <Link
                  to="/gates/gate-designs/arched-gates"
                  title="Arched Gates"
                >
                  <h5>Arched Gates</h5>
                  <p>
                    These gates use, as the name suggests, an arch at the top
                    which can turn a boring gate into one that has a more
                    aesthetic appeal. We can design them out of wood, steel,
                    aluminum, or several other materials.
                  </p>
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gate-svc-cont">
                <Link to="/gates/gate-designs/bell-gates" title="Bell Gates">
                  <h5>Bell Gates</h5>
                  <p>
                    Some gates use a bell-shaped curve to enhance their eye
                    appeal. They combine those with flat tops and those with
                    arched tops to form their unique shape. They are lower on
                    the edges, higher in the middle, and usually open in a
                    French door style.
                  </p>
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gate-svc-cont">
                <Link
                  to="/gates/gate-designs/flat-top-gates"
                  title="Flat Top Gates"
                >
                  <h5>Flat Top Gates</h5>
                  <p>
                    Gates with a flat top are simplistic and are often used in
                    commercial applications because they are usually less
                    expensive and we can construct them from many different
                    kinds of materials.
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="row gates-row4 text-center mt-5"
            id="gate-designs-row"
          >
            <div className="col-md-4">
              <div className="gate-svc-cont">
                <Link to="/gates/gate-types/swing-gates" title="Swinging Gates">
                  <h5>Swinging Gates</h5>
                  <p>
                    A swinging gate opens and closes by using a hinge. They are
                    commonly used in residential applications because they fit
                    nicely in places with limited space.
                  </p>
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gate-svc-cont">
                <Link
                  to="/gates/gate-types/sliding-gates"
                  title="Sliding Gates"
                >
                  <h5>Sliding Gates</h5>
                  <p>
                    A sliding gate works on a track to slide open and closed.
                    They require extra space parallel to the entryway but are
                    great in cases where it is impossible for them to swing
                    open.
                  </p>
                </Link>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="gate-svc-cont">
                <Link
                  to="/gates/gate-designs/custom-gate-designs"
                  title="Custom Gates"
                >
                  <h5>Custom Gates</h5>
                  <p>
                    Need a custom gate built for your property? Check out some
                    of our custom design work! J&J Gates can help you create and
                    build a gate that is unique to your home.
                  </p>
                </Link>
              </div>
            </div>{' '}
          </div>
        </div>
      </Layout>
    )
  }
}

export default DesignPage

export const DesignPageQuery = graphql`
  query DesignPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
